<template>
  <div class="launch-select-user">
    <CompanyFriend
      show
      :type="1"
      :show-header="false"
      :show-company-name="false"
      @onClose="showSelectUserDialog = false"
      @roleCallback="onSelectUserDialogConfirm"
    />
  </div>
</template>

<script>
  import CompanyFriend from '@/components/selectPerson/companyFriend.vue'
  import { createZddLaunch } from '@/model/launch'

  export default {
    name: 'LaunchSelectUser',
    components: { CompanyFriend },
    data() {
      return {
        businessId: undefined,
      }
    },
    created() {
      const { businessId } = this.$route.query
      this.businessId = businessId
    },
    methods: {
      async onSelectUserDialogConfirm(dep, user) {
        let loadingInstance
        try {
          loadingInstance = this.$toast.loading({
            message: '处理中',
            loadingType: 'spinner',
            duration: 90000,
          })
          const userList = [user].map(item => {
            return {
              position: item.position,
              comId: item.comId,
              comName: item.department,
              uid: item.uid,
              handlerName: item.name,
              handlerTel: item.telephone || item.email,
            }
          })
          await createZddLaunch({
            businessId: this.businessId,
            userList,
            vm: this,
          })
        } catch (error) {
          console.error(error)
        } finally {
          loadingInstance && loadingInstance.close()
        }
      },
    },
  }
</script>

<style scoped lang="less"></style>
